<template>
  <div class="min-h-screen">
    <NuxtLoadingIndicator color="#FFFFFF" />
    <div class="page-container grid relative" :class="{ '!flex ml-24': !isFixedSideNav }">
      <SideBar
        class="z-50 h-screen"
        :class="{
          'fixed top-0 left-0': !isFixedSideNav,
          'w-24': !isFixedSideNav && !isOpenSideNav,
          'w-60': !isFixedSideNav && isOpenSideNav,
        }"
        @hovered="onMainSideBarHovered"
      />
      <ContainerReports class="relative overflow-y-auto w-full max-w-full pl-4 pr-3">
        <slot />
      </ContainerReports>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useGeneralStore } from '~/stores/general'

const generalStore = useGeneralStore()
const { setIsAdminMode } = generalStore
const { isFullScreenMode, isOpenSideNav, isFixedSideNav } = storeToRefs(generalStore)

const route = useRoute()

const sidebarwidht = computed(() => (isOpenSideNav.value ? '230px' : '96px'))

useHead({
  htmlAttrs: {
    class: isFullScreenMode.value ? 'h-full overflow-hidden' : '',
  },
})

definePageMeta({
  middleware: 'roles',
})

const onMainSideBarHovered = (val) => {
  if (isFixedSideNav.value) return
  isOpenSideNav.value = val
}

watch(
  () => route.name,
  () => {
    const pageHasAdminPath = route.path.includes('/admin')
    setIsAdminMode(pageHasAdminPath)
  },
)
</script>

<style scoped>
.page-container {
  @apply h-screen;

  grid-template-columns: v-bind(sidebarwidht) 1fr;
}
</style>
